import React, {useEffect, useState} from 'react';
import ContentProvider from "../../core/ContentProvider";
import {Grid, Typography} from "@material-ui/core";
import Layout from "./Layout";

interface QuestionAndAnswer {
    question: string;
    answer: string;
}

const QARow = (props: {
    index: number;
    row: QuestionAndAnswer;
}) => {
    const {
        index,
        row: {
            question,
            answer,
        },
    } = props;

    return (
        <Grid container item xs={12} justify={'center'}>
            <Grid
                item
                xs={12}
                // md={6}
                style={{
                    padding: 10,
                    maxWidth: 700,
                }}
            >
                <Typography variant={'subtitle1'}>{`${index + 1}. ${question}`}</Typography>
                <Typography
                    variant={'body2'}
                    style={{
                        textAlign: 'justify',
                    }}
                >{answer}</Typography>
            </Grid>
        </Grid>
    );
};

const Faq = () => {
    const [
        rows,
        setRows,
    ] = useState<QuestionAndAnswer[]>([]);

    const fetchRows = async () => {
        const faq: any = await ContentProvider.getFaq();
        const { answers } = faq[0];

        const answersParsed: QuestionAndAnswer[] = (answers as string)
            .split('---\n')
            .map((row) => row.split('\n'))
            .map(([question, answer]) => ({question, answer}));

        return answersParsed;
    };

    useEffect(() => {
        fetchRows()
            .then((fetchedRows) => setRows(fetchedRows))
            .catch((err) => {
                console.error(err);
            })
    }, []);

    return (
        <Layout>
            <Grid
                container
                justify={'center'}
                style={{
                    paddingBottom: 60,
                }}
                id={'faq'}
            >
                <h3
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        marginTop: 30,
                        marginBottom: 30,
                    }}
                >FAQ</h3>

                {rows.map((row, index) => (
                    <QARow key={`row_${index}`} row={row} index={index} />
                ))}
            </Grid>
        </Layout>
    );
};

export default Faq;
