import React from "react";
import Carousel, {Settings} from "react-slick";

import image0 from "../imgs/carousel/small/jetson_one_render_24.jpg";
import image1 from "../imgs/carousel/small/jetson_one_render_12.jpg";
import image2 from "../imgs/carousel/small/jetson_one_render_14.jpg";
import image3 from "../imgs/carousel/small/jetson_one_render_16.jpg";
import imageA from "../imgs/carousel/small/jetson_one_render_17.jpg";
import image4 from "../imgs/carousel/small/jetson_one_render_18.jpg";
import image5 from "../imgs/carousel/small/jetson_one_render_19.jpg";
import image6 from "../imgs/carousel/small/jetson_one_render_20.jpg";
import image7 from "../imgs/carousel/small/jetson_one_render_13.jpg";
import image8 from "../imgs/carousel/small/jetson_one_render_03.jpg";
import image9 from "../imgs/carousel/small/jetson_one_render_11.jpg";

interface SectionCarouselProps extends Settings {

}

export default function SectionCarousel(props: SectionCarouselProps) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        ...props
    };
    return (
        <Carousel {...settings}>
            {[
                image0,
                image1,
                image2,
                image3,
                image4,
                image5,
                image6,
                imageA,
                image7,
                image8,
                image9,
            ].map((image, index) => (
                <div key={`image_${index}`}>
                    <img src={image} alt={`${index}`} className="slick-image" />
                </div>
            ))}
        </Carousel>
    );
}
