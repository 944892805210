import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import "./assets/scss/material-kit-react.scss?v=1.9.0";
import {Home} from "./views/components/Home";
import Faq from "./views/components/Faq";

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={'/'} exact>
                    <Home />
                </Route>

                <Route path={'/faq'} exact>
                    <Faq />
                </Route>

            </Switch>
        </BrowserRouter>
    );
}

export default App;
