import React from 'react';

import Layout from "./Layout";
import HomeText from "./HomeText";
import Specification from "./Specification";
import {Prototype} from "./Prototype";
import Order from "./Order";
import AboutUs from "./AboutUs";

export const Home = () => (
    <Layout>
        <HomeText/>

        <Specification/>

        <Prototype/>

        <Order/>

        <AboutUs/>
    </Layout>
);
