import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Grid, Hidden, IconButton} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import styles from "../../assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles as any);

export default function Footer(props: any) {
    const classes = useStyles();
    const { whiteFont } = props;
    const footerClasses = classNames({
        [classes.footer]: true,
        [classes.footerWhiteFont]: whiteFont
    });

    return (
        <footer className={footerClasses}>
            <div className={classes.container}>
                <Grid item xs={12} style={{paddingTop: 20}}>
                    <p>Jetson is a registered trademark owned by Jetson AB</p>
                    <p>Jetson AB | Mailbox 3600 | 111 74 Stockholm | Sweden</p>
                    <a href={'mailto:info@jetsonaero.com'}>info@jetsonaero.com</a>
                </Grid>
                <Grid item xs={12}>
                    <a href={'https://www.facebook.com/jetsonaero/'} target={'blank'}><IconButton><FacebookIcon /></IconButton></a>
                    <a href={'https://www.instagram.com/jetsonaero/'} target={'blank'}><IconButton><InstagramIcon /></IconButton></a>
                </Grid>
                <Hidden smDown>
                    <div>
                        &copy; {new Date().getFullYear()} Jetson AB
                    </div>
                </Hidden>
                <Hidden smUp>
                    <div>
                        &copy; {new Date().getFullYear()} Jetson AB
                    </div>
                </Hidden>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    whiteFont: PropTypes.bool
};
