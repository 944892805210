import React from 'react';
import {Grid} from "@material-ui/core";
import ReactYoutube from 'react-youtube';

export const Prototype = () => {
    const calculateVideoHeight = (): number => window.innerWidth > window.innerHeight
        ? Math.round(window.innerHeight * 0.4)
        : 200;

    const [
        videoHeight,
        setVideoHeight,
    ] = React.useState(calculateVideoHeight());

    window.addEventListener('resize', () => {
        setVideoHeight(calculateVideoHeight());
    });

    return (
        <Grid
            container
            style={{
                backgroundColor: '#000000',
                color: 'white',
            }}
        >

            <Grid item xs={12}>
                <div
                    style={{
                        maxWidth: 560,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        padding: 30,
                    }}
                >
                    <ReactYoutube
                        videoId={'WoFQ1NTwy04'}
                        opts={{
                            height: `${videoHeight}px`,
                            width: '100%',
                        }}
                    />
                </div>
            </Grid>

            <Grid
                item
                xs={12}
                style={{
                    textAlign: 'center',
                    padding: 0,
                }}
            >
                <h3
                    style={{
                        fontWeight: 'bold',
                        marginTop: 0,
                        marginBottom: 5,
                    }}
                >The Prototype</h3>
            </Grid>

            <Grid
                item
                xs={12}
                style={{
                    padding: 30,
                    maxWidth: 900,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 20,
                    marginBottom: 30,
                    color: 'white',
                    textAlign: 'justify',
                }}
            >
                <p style={{fontSize: '110%'}}>The Jetson Prototype was our first future "flying car". Just as the final product above, Jetson ONE, it was an octocopter in H configuration, with dual engines for each corner. In total, eight motors and propellers. Safety was an important concern. With this configuration, an engine failure would not be a catastrophic event. </p>

                <p style={{fontSize: '110%'}}>The prototype was developed during the autumn of 2017. After much lab tests, development and flight computer programming, the first successful flight was in January 2018. First manned flight was on the 28th of February 2018.</p>

                <p style={{fontSize: '110%'}}>It turned out to be a fantastic experience that everyone should try. Being able to move through the air so effortlessly without vibrations or noise - it was amazing. Have you ever had a dream where you could fly? This was it. By simple controls, we could move up, left, right, anywhere we wanted to go. Perfectly stable, with the fly-by-wire system we had developed. If we took our hands off the joysticks, our machine simply stopped and hovered in the air. </p>

                <p style={{fontSize: '110%'}}>With our ultra-simple flight control system we were sure that everyone could be a pilot. This was the future of personal transport where you could effortlessly go, in the air, directly from start to destination without standing still in traffic ever again.</p>

                <p style={{fontSize: '110%'}}>After the successful completion of the working prototype and many test flights, development of the Jetson ONE started. A second generation of our the prototype that was aimed for the consumer. A beautiful design with all the necessary safety features and simple operation.</p>

                <p style={{fontSize: '110%'}}>Jetson ONE was finished during the late spring of 2020, and is available to buy. We are now inviting you to experience the intense euphoria of effortlessly moving through the air.</p>

                <p style={{fontSize: '110%'}}>“Once you have tasted flight, you will forever walk the earth with your eyes turned skyward, for there you have been, and there you will always long to return.”</p>

                <p style={{fontSize: '110%'}}>A quote by Leonardo da Vinci, who lived less than 20 kilometers from where we developed and did the flight tests of the prototype in Tuscany.</p>
            </Grid>
        </Grid>
    );
};
