import React from 'react';
import {Grid, Hidden, Table, TableBody, TableCell, TableRow} from "@material-ui/core";

import JetsonOneRender from '../imgs/carousel/jetson_one_render_05.jpg';

const Specification = () => {
    return (
        <Grid container justify={'center'} style={{backgroundColor: '#242424', color: 'white'}}>
            <Grid item xs={12} md={6}>
                <h3
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        marginBottom: 30,
                        marginTop: 40,
                    }}>Jetson ONE Specification</h3>
                <Hidden smDown>
                    <Table>
                        <TableBody>
                            {[
                                ['AUW - all up weight (inc. battery)', '90kg/198lb'],
                                ['Dimensions', 'length 2845mm / width 2400mm / height 1030mm'],
                                ['Flight time (85kg/187lbs pilot weight)', '15 minutes'],
                                ['Top speed (level flight)', 'restricted to 102km/h(63mph)'],
                                ['Max power output (8 motors)', '84kW'],
                                ['Motor type', 'brushless outrunner'],
                                ['Flight computer redundancy', 'Triple redundant IMU/gyro/accelerometer/compass'],
                                ['Chassis type', 'All-aluminium(7020) safety cell with crumble zones'],
                                ['Seat type', 'Carbon/aramid fiber composite with safety padding'],
                                ['Seatbelt harness type', '4-point quick release safety harness(Sparco)'],
                                ['Aircraft category', 'Experimental ultralight in compliance with FAA Part 103'],
                                ['Battery type', 'Swappable 52V li-ion (20700) battery packs'],
                                ['Flight controls', 'Joystick/throttle lever/yaw pedals'],
                                ['Safety features', 'Complete propulsion redundancy, triple redundant flight computer, ballistic parachute, safety cell chassis, crumble zones, lidar aided obstacle and terrain avoidance, hands free hover and emergency hold functions, propeller guards, composite seat with harness'],
                            ].map(([label, value], index) => (
                                <TableRow key={`row_${index}`}>
                                    <TableCell style={{textAlign: 'right', fontWeight: 'bold', width: '50vw', color: 'white'}}>{label}</TableCell>
                                    <TableCell style={{width: '50vw', color: 'white'}}>{value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Hidden>
                <Hidden smUp>
                    <Table>
                        <TableBody>
                            {[
                                ['AUW - all up weight (inc. battery)', '90kg/198lb'],
                                ['Dimensions', 'length 2845mm / width 2400mm / height 1030mm'],
                                ['Flight time (85kg/187lbs pilot weight)', '15 minutes'],
                                ['Top speed (level flight)', 'restricted to 102km/h(63mph)'],
                                ['Max power output (8 motors)', '84kW'],
                                ['Motor type', 'brushless outrunner'],
                                ['Flight computer redundancy', 'Triple redundant IMU/gyro/accelerometer/compass'],
                                ['Chassis type', 'All-aluminium(7020) safety cell with crumble zones'],
                                ['Seat type', 'All-aluminium(7020) safety cell with crumble zones'],
                                ['Chassis type', 'Carbon/aramid fiber composite with safety padding'],
                                ['Seatbelt harness type', '4-point quick release safety harness(Sparco)'],
                                ['Aircraft category', 'Experimental ultralight in compliance with FAA Part 103'],
                                ['Battery type', 'Swappable 52V li-ion (20700) battery packs'],
                                ['Flight controls', 'Joystick/throttle lever/yaw pedals'],
                                ['Safety features', 'Complete propulsion redundancy, triple redundant flight computer, ballistic parachute, safety cell chassis, crumble zones, lidar aided obstacle and terrain avoidance, hands free hover and emergency hold functions, propeller guards, composite seat with harness'],
                            ].map(([label, value], index) => [
                                (
                                    <TableRow key={`row_${index}_1`}>
                                        <TableCell style={{textAlign: 'center', fontWeight: 'bold', width: '50vw', color: 'white'}}>{label}</TableCell>
                                    </TableRow>
                                ),
                                (
                                    <TableRow key={`row_${index}_2`}>
                                        <TableCell style={{width: '50vw', color: 'white', textAlign: 'center'}}>{value}</TableCell>
                                    </TableRow>
                                ),
                            ])}
                        </TableBody>
                    </Table>
                </Hidden>
            </Grid>

            <Grid item xs={12}>
                <img src={JetsonOneRender} style={{width: '100%'}} alt={'Pure Jetson One'} />
            </Grid>
        </Grid>
    );
};

export default Specification;
