import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    title: {
        fontSize: "4.2rem",
        fontWeight: "600",
        display: "inline-block",
        position: "relative"
    },
} as any);

const HomeText = () => {
    const classes = useStyles();

    return (
        <div className={classes.sections} style={{
            padding: 30,
            maxWidth: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 40,
            marginBottom: 30,
            fontSize: '110%',
        }}>
            <div className={classes.title}>
                <h3>Hi, welcome to Jetson!</h3>

                <h4>Jetson is a Swedish company with a mission to change the way we travel. We aim to make the skies available for everyone with our safe personal electric aerial vehicle.</h4>

                <h4>Our prototype "proof of concept" was finished in the spring of 2018, and until now we have been very busy working on a consumer friendly version.</h4>

                <h4>That project resulted in Jetson ONE, a commercially available personal electric aerial vehicle that you can own and fly. Read more about it below.</h4>

                <h4>Jetson was founded by Peter Ternström and Tomasz Patan in 2017. We intend to make everyone a pilot.</h4>
            </div>
        </div>
    );
};

export default HomeText;
