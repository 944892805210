import React from 'react';
import {Grid, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import HeaderImage from '../imgs/carousel/jetson_one_render_21.jpg';
import ContentProvider, {ProductionSlot} from '../../core/ContentProvider';
import moment from "moment";

interface OrderState {
    fetching: boolean;
    slots: ProductionSlot[];
}

class Order extends React.Component<any, OrderState> {
    state = {
        fetching: false,
        slots: [],
    };

    componentDidMount() {
        this.fetchSlots();
    }

    fetchSlots = async () => {
        const slots = await ContentProvider.getProductionSlots();
        this.setState({
            fetching: false,
            slots: slots,
        });
    };

    parseSlots = (): string[][] => {
        const parsedSlots = this.state.slots
            .sort(({date: leftDate}, {date: rightDate}) => {
                if (leftDate > rightDate) {
                    return 1;
                } else if (leftDate < rightDate) {
                    return -1;
                } else {
                    return 0;
                }
            })
            .map(({date, place}) => {
                const dateParsed = moment(date).format('YYYY MMM DD').toUpperCase();

                return [
                    dateParsed,
                    place,
                ];
            });

        if (parsedSlots.length >= 12) {
            return parsedSlots;
        }

        const slotsToReturn = [];
        for (let i = 0; i < 12; i++) {
            if (parsedSlots[i]) {
                slotsToReturn.push(parsedSlots[i]);
            } else {
                slotsToReturn.push(['', '']);
            }
        }
        return slotsToReturn;
    };

    render() {
        return (
            <Grid container>
                <Grid item xs={12} style={{
                    maxWidth: '900px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 40,
                    padding: 20,
                    textAlign: 'center',
                }}>
                    <img
                        src={HeaderImage}
                        style={{
                            width: '80%',
                            marginTop: 30,
                        }}
                        alt={'Jetson you can order'}
                    />

                    <h3
                        style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                    >Order your own</h3>

                    <p style={{textAlign: 'justify'}}>A complete vehicle is 59 000 USD and is delivered to you as a partially (50%) assembled kit for home completion. It contains everything you need, from the aluminium space frame to motor controllers, propellers and motors. You will also receive detailed build instructions.</p>

                    <p style={{textAlign: 'justify'}}>If you want to have your own Jetson this winter, please contact us for purchase. 14 200 USD deposit to reserve a build slot in 2020. We plan to have twelve build slots for autumn 2020, with production starting on the 1st of October.</p>

                    <p style={{textAlign: 'justify'}}>Please contact Peter Ternström at <a href={'mailto:peter@jetsonaero.com'}>peter@jetsonaero.com</a> to order, or for questions. </p>

                    <h3
                        style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                    >Build Slots 2020</h3>

                    <Table style={{
                        maxWidth: 700,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}>
                        <TableBody>
                            {this.parseSlots().map(([date, place], index) => {
                                return (
                                    <TableRow key={`i${index}`}>
                                        <TableCell
                                            style={{
                                                width: 50,
                                                paddingBottom: 20,
                                                paddingTop: 20,
                                            }}
                                        >{`${index + 1}.`}</TableCell>

                                        <TableCell
                                            style={{
                                                width: 150,
                                            }}
                                        >{date ? date : 'Slot available'}</TableCell>

                                        <TableCell
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >{place ? `build slot reserved for client in ${place}` : ''}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                </Grid>
            </Grid>
        );
    }
}

export default Order;
