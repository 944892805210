import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";

import ContentProvider, {Founder} from "../../core/ContentProvider";

interface ISinglePersonProps {
    name: string;
    description: string;
    image: string;
}

const SinglePerson = (props: ISinglePersonProps) => {
    return (
        <Grid
            item
            xs={12}
            md={4}
            style={{
                maxWidth: 500,
                textAlign: 'center',
                boxShadow: '0 8px 12px 2px rgba(255, 255, 255, 0.1), 0 6px 15px 5px rgba(255, 255, 255, 0.15), 0 4px 5px -5px rgba(255, 255, 255, 0.2)',
                backgroundColor: 'white',
                borderRadius: 4,
                marginBottom: 10,
                marginLeft: 10,
                marginRight: 10,
                padding: 10,
            }}
        >
            <img
                src={props.image}
                alt={props.name}
                style={{
                    width: '100%',
                }}
            />

            <h3
                style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginTop: 30,
                }}
            >{props.name}</h3>

            <p
                style={{
                    padding: 10,
                    textAlign: 'justify',
                }}
            >{props.description}</p>
        </Grid>
    );
};

interface AboutUsState {
    founders: Founder[];
}

const AboutUs = () => {
    const [ { founders }, setState] = useState<AboutUsState>({
        founders: [],
    });

    useEffect(() => {
        ContentProvider.getFounders().then((foundersFetched) => {
            setState({
                founders: foundersFetched,
            });
        });
    }, []);

    return (
        <Grid
            container
            style={{
                backgroundColor: 'black',
                padding: 10,
            }}
            justify={'center'}
        >
            <Grid item xs={12}>
                <h3
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: 'white',
                        marginTop: 30,
                        marginBottom: 40,
                    }}
                >About Us</h3>
            </Grid>

            {founders.map((founder: Founder, index: number) => (
                <SinglePerson
                    key={`founder_${index}`}
                    name={founder.name}
                    description={founder.bio.content[0].content[0].value}
                    image={founder.picture.fields.file.url}
                />
            ))}
        </Grid>
    );
};

export default AboutUs;
