import * as contentful from 'contentful';

export interface Founder {
    order: number;
    name: string;
    bio: {
        content: Array<{
            content: Array<{
                value: string;
            }>;
        }>;
    };
    picture: {
        fields: {
            file: {
                contentType: string;
                details: {

                };
                fileName: string;
                url: string;
            };
        };
    };
}

export interface ProductionSlot {
    date: string;
    place: string;
}

class ContentProvider {
    private _contentfulClient: contentful.ContentfulClientApi | undefined;

    public async getFounders(): Promise<Founder[]> {
        const res = await this.getClient().getEntries<Founder>({
            content_type: 'founder',
        });

        return res.items
            .map((item) => item.fields)
            .sort((left, right) => {
                return left.order - right.order;
            })
    }

    public async getProductionSlots(): Promise<ProductionSlot[]> {
        const res = await this.getClient().getEntries<ProductionSlot>({
            content_type: 'productionSlots',
        });

        return res.items.map((item) => item.fields);
    }

    public async getFaq() {
        const res = await this.getClient().getEntries<ProductionSlot>({
            content_type: 'faq',
        });

        return res.items.map((item) => item.fields);
    }

    private getClient(): contentful.ContentfulClientApi {
        if (!this._contentfulClient) {
            this._contentfulClient = contentful.createClient({
                space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
                accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
            });
        }

        return this._contentfulClient;
    }
}

export default new ContentProvider();
