import React, {useState} from 'react';

import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import Header from "./Header";
import SectionCarousel from "./Carousel";
import Footer from "./Footer";
import {Button, List, ListItem} from "@material-ui/core";
// import {Hidden} from "@material-ui/core";
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    brand: {
        color: "#FFFFFF",
        textAlign: "left"
    },
    subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px 0 0"
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3",
    },
    mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    link: {
        textDecoration: "none"
    },
    textCenter: {
        textAlign: "center"
    }
} as any);

interface MenuListItemProps {
    label: string;
    target: string;
}

const MenuListItem = (props: MenuListItemProps) => (
    <ListItem>
        <Link to={props.target} style={{width: '100%',}}>
            <Button
                fullWidth
            >{props.label}</Button>
        </Link>
    </ListItem>
);

interface LayoutProps {
    children: any;
}

const Layout = (props: LayoutProps) => {
    const classes = useStyles();

    const wideMarginsRequired = (): boolean => {
        return window.innerWidth > 768;
    };

    const [wideMargins, setMargins] = useState(wideMarginsRequired());
    window.addEventListener('resize', () => {
        const newMargins = wideMarginsRequired();
        if (wideMargins === newMargins) {
            return;
        }

        setMargins(newMargins);
    });

    return (
        <div>
            <Header
                brand={'Jetson Aero'}
                color={'transparent'}
                changeColorOnScroll={{
                    height: 400,
                    color: 'dark',
                }}
                fixed
                rightLinks={(
                    <List>
                        <MenuListItem
                            label={'Home'}
                            target={'/'}
                        />
                        <MenuListItem
                            label={'FAQ'}
                            target={'/faq'}
                        />
                    </List>
                )}
            />

            <SectionCarousel
                adaptiveHeight={true}
            />

            <div
                className={classNames(classes.main, classes.mainRaised)}
                style={{
                    ...(wideMargins ? {
                        marginLeft: 30,
                        marginRight: 30,
                    } : {
                        marginLeft: 0,
                        marginRight: 0,
                    })
                }}
            >
                {props.children}
            </div>

            <Footer />
        </div>
    );
}

export default Layout;
